import React from 'react';

interface Props {
  color: string;
}

export const HomeIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.375 31.125V19.4583C22.375 19.0716 22.2214 18.7006 21.9479 18.4271C21.6744 18.1536 21.3034 18 20.9167 18H15.0833C14.6966 18 14.3256 18.1536 14.0521 18.4271C13.7786 18.7006 13.625 19.0716 13.625 19.4583V31.125"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.875 15.0833C4.8749 14.6591 4.96736 14.2399 5.14594 13.855C5.32452 13.4701 5.58491 13.1289 5.90896 12.855L16.1173 4.10645C16.6437 3.66153 17.3107 3.41742 18 3.41742C18.6893 3.41742 19.3563 3.66153 19.8827 4.10645L30.091 12.855C30.4151 13.1289 30.6755 13.4701 30.8541 13.855C31.0326 14.2399 31.1251 14.6591 31.125 15.0833V28.2083C31.125 28.9819 30.8177 29.7237 30.2707 30.2707C29.7237 30.8177 28.9819 31.125 28.2083 31.125H7.79167C7.01812 31.125 6.27625 30.8177 5.72927 30.2707C5.18229 29.7237 4.875 28.9819 4.875 28.2083V15.0833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
