interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const ChartIcon: React.FC<Props> = ({
  color,
  width = '36',
  height = '36',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.875 22.3754V25.0001C4.875 26.6336 4.875 27.4497 5.1929 28.0737C5.47253 28.6225 5.9184 29.0695 6.4672 29.3492C7.0905 29.6667 7.90687 29.6667 9.53716 29.6667H31.1253M4.875 22.3754V7.79175M4.875 22.3754L10.4945 17.6925L10.4992 17.6887C11.5158 16.8416 12.025 16.4172 12.5773 16.2448C13.2296 16.0412 13.9323 16.0734 14.5635 16.3352C15.0986 16.5572 15.5679 17.0265 16.5063 17.9649L16.5157 17.9743C17.4687 18.9273 17.9465 19.4051 18.4902 19.6266C19.1336 19.8887 19.8499 19.9113 20.5094 19.6926C21.0684 19.5071 21.579 19.0611 22.6 18.1677L31.125 10.7084"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
