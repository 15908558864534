'use client';

import { Layout } from '@/components/layout/layout';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import '@/styles/globals.css';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  // const { is_expired = false } = useSelector(
  //   (state: RootState) => state.auth.subscription,
  // );
  //
  // useEffect(() => {
  //   // is_expired ? router.replace('/subscription') : null;
  // }, [is_expired, router]);

  useEffect(() => {
    if (!isAuthenticated) {
      router.replace('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, router]);

  if (!isAuthenticated) {
    return null; // Optionally render a loading spinner or nothing while redirecting
  }

  return <Layout>{children}</Layout>;
}
