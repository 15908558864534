import React from 'react';

interface Props {
  color: string;
}

export const TeamIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8334 31.125V28.2083C23.8334 26.6612 23.2188 25.1775 22.1249 24.0835C21.0309 22.9896 19.5472 22.375 18.0001 22.375H9.25008C7.70299 22.375 6.21925 22.9896 5.12529 24.0835C4.03133 25.1775 3.41675 26.6612 3.41675 28.2083V31.125"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6251 16.5417C16.8467 16.5417 19.4584 13.93 19.4584 10.7083C19.4584 7.48667 16.8467 4.875 13.6251 4.875C10.4034 4.875 7.79175 7.48667 7.79175 10.7083C7.79175 13.93 10.4034 16.5417 13.6251 16.5417Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5832 31.125V28.2083C32.5823 26.9158 32.1521 25.6603 31.3602 24.6388C30.5684 23.6173 29.4597 22.8877 28.2083 22.5646"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8333 5.06459C25.088 5.38586 26.2002 6.11561 26.9944 7.13879C27.7886 8.16197 28.2197 9.42038 28.2197 10.7156C28.2197 12.0109 27.7886 13.2693 26.9944 14.2925C26.2002 15.3157 25.088 16.0454 23.8333 16.3667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
