import React from 'react';

interface Props {
  color: string;
}

export const ExpenseIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.125 29.6667L31.125 22.375C31.125 21.5696 30.4721 20.9167 29.6667 20.9167L22.375 20.9167C21.5696 20.9167 20.9167 21.5696 20.9167 22.375L20.9167 29.6667C20.9167 30.4721 21.5696 31.125 22.375 31.125L29.6667 31.125C30.4721 31.125 31.125 30.4721 31.125 29.6667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.87508 15.0833L23.8334 15.0833C24.2202 15.0833 24.5911 14.9297 24.8646 14.6562C25.1381 14.3827 25.2917 14.0118 25.2917 13.625L25.2917 6.33333C25.2917 5.94656 25.1381 5.57563 24.8646 5.30214C24.5911 5.02865 24.2202 4.875 23.8334 4.875L6.33341 4.875C5.94664 4.875 5.57571 5.02864 5.30222 5.30214C5.02873 5.57563 4.87508 5.94656 4.87508 6.33333L4.87508 23.8333C4.87508 24.2201 5.02872 24.591 5.30221 24.8645C5.57571 25.138 5.94664 25.2917 6.33341 25.2917L13.6251 25.2917C14.0119 25.2917 14.3828 25.138 14.6563 24.8645C14.9298 24.591 15.0834 24.2201 15.0834 23.8333L15.0834 4.875"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
