'use client';

import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface QuoteContext {
    selectedUuid: string | null;
    setSelectedUuid: Dispatch<SetStateAction<string | null>>;
}

export const QuoteContext = createContext<QuoteContext>({
    selectedUuid: '',
    setSelectedUuid: () => { },
});

export const useQuoteContext = () => {
    return useContext(QuoteContext);
};