import React from 'react';

interface Props {
  color: string;
}

export const SupplierIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5833 26.7498H9.24996C8.47641 26.7498 7.73455 26.4425 7.18756 25.8956C6.64058 25.3486 6.33329 24.6067 6.33329 23.8332V10.7082C6.33329 9.93462 6.026 9.19276 5.47902 8.64578C4.93204 8.09879 4.19017 7.7915 3.41663 7.7915"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2917 20.9165V6.33317C25.2917 5.55962 24.9844 4.81776 24.4374 4.27078C23.8904 3.72379 23.1485 3.4165 22.375 3.4165H20.9167C20.1431 3.4165 19.4013 3.72379 18.8543 4.27078C18.3073 4.81776 18 5.55962 18 6.33317V20.9165"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6666 9.25H13.625C12.8195 9.25 12.1666 9.90292 12.1666 10.7083V19.4583C12.1666 20.2637 12.8195 20.9167 13.625 20.9167H29.6666C30.472 20.9167 31.125 20.2637 31.125 19.4583V10.7083C31.125 9.90292 30.472 9.25 29.6666 9.25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.75 32.5833C28.3609 32.5833 29.6667 31.2775 29.6667 29.6667C29.6667 28.0558 28.3609 26.75 26.75 26.75C25.1392 26.75 23.8334 28.0558 23.8334 29.6667C23.8334 31.2775 25.1392 32.5833 26.75 32.5833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 32.5833C15.2359 32.5833 16.5417 31.2775 16.5417 29.6667C16.5417 28.0558 15.2359 26.75 13.625 26.75C12.0142 26.75 10.7084 28.0558 10.7084 29.6667C10.7084 31.2775 12.0142 32.5833 13.625 32.5833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
