'use client';

import React, { useState } from 'react';
import { useLockedBody } from '../hooks/useBodyLock';
import { NavbarWrapper } from '../navbar/navbar';
// import { SidebarWrapper } from '../sidebar/sidebar';
import { SidebarContext } from './layout-context';
import { BottomNav } from '../bottomnav/index';
import { BottomNavMenu } from '../bottomnav/bottomnav-menu';
import BreadcrumbComponent from '@/components/breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { QuoteContext } from './quote-context';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [_, setLocked] = useLockedBody(false);
  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setLocked(!sidebarOpen);
  };

  const breadcrumbs = useSelector(
    (state: RootState) => state.breadcrumb.breadcrumbs,
  );

  return (
    <SidebarContext.Provider
      value={{
        collapsed: sidebarOpen,
        setCollapsed: handleToggleSidebar,
      }}
    >
      <QuoteContext.Provider value={{ selectedUuid, setSelectedUuid }}>
        <section className="flex bg-light-primary-100 mb-[70px]">
          <NavbarWrapper>
            <div className="pb-4 bg-light-primary-100">
              <BreadcrumbComponent breadcrumbPath={breadcrumbs} />
            </div>
            <div className="h-[max-content] min-h-[calc(100vh-205px)] w-auto overflow-scroll scrollbar-hide rounded-xl bg-light-layout-background">
              <div className="p-5">{children}</div>
            </div>
          </NavbarWrapper>
        </section>
        <BottomNav>
          <BottomNavMenu />
        </BottomNav>
      </QuoteContext.Provider>
    </SidebarContext.Provider>
  );
};
