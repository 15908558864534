import React from 'react';

interface Props {
  color: string;
}

export const CustomerIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.375 19.4583C22.375 18.298 21.9141 17.1852 21.0936 16.3647C20.2731 15.5443 19.1603 15.0833 18 15.0833C16.8397 15.0833 15.7269 15.5443 14.9064 16.3647C14.0859 17.1852 13.625 18.298 13.625 19.4583"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33337 28.9375V7.0625C6.33337 6.09557 6.71749 5.16824 7.40121 4.48451C8.08494 3.80079 9.01227 3.41667 9.97921 3.41667H28.2084C28.5951 3.41667 28.9661 3.57032 29.2396 3.84381C29.5131 4.1173 29.6667 4.48823 29.6667 4.87501V31.125C29.6667 31.5118 29.5131 31.8827 29.2396 32.1562C28.9661 32.4297 28.5951 32.5833 28.2084 32.5833H9.97921C9.01227 32.5833 8.08494 32.1992 7.40121 31.5155C6.71749 30.8318 6.33337 29.9044 6.33337 28.9375ZM6.33337 28.9375C6.33337 27.9706 6.71749 27.0432 7.40121 26.3595C8.08494 25.6758 9.01227 25.2917 9.97921 25.2917H29.6667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15.0833C19.6109 15.0833 20.9167 13.7775 20.9167 12.1667C20.9167 10.5558 19.6109 9.25 18 9.25C16.3892 9.25 15.0834 10.5558 15.0834 12.1667C15.0834 13.7775 16.3892 15.0833 18 15.0833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
