import React from 'react';

interface Props {
  color: string;
}

export const ProductIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4375 6.72708L24.5625 14.2375"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.125 12.1667C31.1245 11.6552 30.9895 11.1529 30.7335 10.71C30.4775 10.2672 30.1096 9.89949 29.6667 9.64376L19.4583 3.81042C19.0149 3.55443 18.512 3.41966 18 3.41966C17.488 3.41966 16.9851 3.55443 16.5417 3.81042L6.33333 9.64376C5.89038 9.89949 5.52247 10.2672 5.26651 10.71C5.01054 11.1529 4.87552 11.6552 4.875 12.1667V23.8333C4.87552 24.3448 5.01054 24.8472 5.26651 25.29C5.52247 25.7328 5.89038 26.1005 6.33333 26.3563L16.5417 32.1896C16.9851 32.4456 17.488 32.5804 18 32.5804C18.512 32.5804 19.0149 32.4456 19.4583 32.1896L29.6667 26.3563C30.1096 26.1005 30.4775 25.7328 30.7335 25.29C30.9895 24.8472 31.1245 24.3448 31.125 23.8333V12.1667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3125 10.7083L18 18L30.6875 10.7083"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 32.5833V18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
