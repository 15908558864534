interface Props {
  color: string;
}

export const InventoryIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.0833 5H6.91666C5.53124 5 4.5 6.05625 4.5 7.5V11.5C4.5 12.445 4.74156 13.2094 5.53124 13.6687V28.5C5.53124 30 6.5 31 8.5 31H27.5C29.5 31 30.4687 30 30.4687 28.5V13.6687C31.2584 13.2094 31.5 12.445 31.5 11.5V7.5C31.5 6.05625 30.4687 5 29.0833 5ZM28.5 29H7.5V14H28.5V29ZM29.5 12H6.55456V7H29.5V12Z"
        fill={color}
      />
      <path d="M14.5 19H21.5V21H14.5V19Z" fill={color} />
    </svg>
  );
};
